const $ = require("jquery");
window.$ = $;
require("bootstrap");

$(document).ready(function() {
	$("#scroll-button").click(function(){
		$("html").animate(
			{scrollTop: $("#description").offset().top - $("header").height()},
			500
		);
	});

	$("#home-link").click(function(){
		$("html").animate(
			{scrollTop: 0},
			500
		);
		return false;
	});

	$("#about-link").click(function(){
		$("html").animate(
			{scrollTop: $("#description").offset().top - $("header").height()},
			500
		);
		return false;
	});

	$("#history-link").click(function(){
		$("html").animate(
			{scrollTop: $("#history").offset().top - $("header").height()},
			500
		);
		return false;
	});

	$("#contact-link").click(function(){
		$("html").animate(
			{scrollTop: $("#contact").offset().top - $("header").height()},
			500
		);
		return false;
	});
});